<template>
    <el-main>
        <div class="header">
            <el-button type="primary" size="small">添加课程</el-button>
            <el-button type="primary" size="small" @click="showClassify = !0">管理分类</el-button>
        </div>
        <el-form class="el-form-search" label-width="90px">
            <el-form-item label="课程标题：">
                <el-input size="small" placeholder="请输入课程标题" v-model="title"></el-input>
            </el-form-item>
            <el-form-item label="课程分类：">
                <el-select size="small" clearable v-model="cate_id" placeholder="请选择">
                    <el-option v-for="item in courseClassify" :key="item.id" :label="item.name"
                        :value="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="是否上架：">
                <el-select size="small" v-model="status" placeholder="请选择">
                    <el-option label="全部" value=""></el-option>
                    <el-option label="是" :value="1"></el-option>
                    <el-option label="否" :value="0"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label=" " label-width="25px">
                <el-button size="small" type="primary" @click="search">搜索</el-button>
                <el-button size="small" type="text" @click="cancelSearch">清空搜索条件</el-button>
            </el-form-item>
        </el-form>
        <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }">
            <template slot="empty">
                <No />
            </template>
            <el-table-column prop="sort" fixed label="排序" width="75" align="center">
                <template #header>
                    <div class="rank">
                        <span>排序</span>
                        <i class="el-icon-edit-outline"></i>
                    </div>
                </template>
                <template slot-scope="scope">
                    <el-input v-model="scope.row.sort" @change="updateDate(scope.row, 1)" size="small"></el-input>
                </template>
            </el-table-column>
            <el-table-column prop="picture" label="文章主图" align="center">
                <template slot-scope="scope">
                    <el-image :src="scope.row.image" style="width: 50px; height: 50px"></el-image>
                </template>
            </el-table-column>
            <el-table-column prop="title" label="课程标题" align="center"></el-table-column>
            <el-table-column prop="price" label="课程费用" align="center"></el-table-column>
            <el-table-column prop="recommended" label="是否推荐" align="center">
                <template slot-scope="scope">
                    {{ scope.row.recommended ? '是' : '否' }}
                </template>
            </el-table-column>
            <el-table-column prop="status" label="上/下架" align="center">
                <template #header>
                    <div class="rank">
                        <span>上/下架</span>
                        <i class="el-icon-edit-outline"></i>
                    </div>
                </template>
                <template v-slot="{ row }">
                    <el-button @click="updateDate(row, 2)" :type="!row.status ? 'primary' : ''" size="small">{{ row.status ?
                        '下架' : '上架' }}</el-button>
                </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                    <el-button @click="edit(scope.row)" type="text" size="small">课程目录</el-button>
                    <el-button @click="$router.push({ path: '/beauty/curriculum/info', query: { id: scope.row.id } })"
                        type="text" size="small">编辑</el-button>
                    <el-button @click="del(scope.row.id)" type="text" size="small">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <Paging :total="total" :page="page" :pageNum="rows" @updatePageNum="updateData"></Paging>
        <el-dialog title="分类管理" :visible.sync="showClassify" width="700px">
            <el-button style="margin-bottom: 15px" type="primary" size="small" @click="addClassify">添加分类</el-button>
            <el-table :data="courseClassifyList" :header-cell-style="{ 'background-color': '#F8F9FA' }">
                <template slot="empty">
                    <No />
                </template>
                <el-table-column prop="sort" label="排序" align="center"></el-table-column>
                <el-table-column prop="name" label="分类名称" align="center"></el-table-column>
                <el-table-column label="操作" align="center">
                    <template v-slot="{ row }">
                        <el-button type="text" @click="editClassify(row)">编辑</el-button>
                        <el-button type="text" @click="delClassify(row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <Paging :total="classifyTotal" :page="classifyPage" :pageNum="classifyRows" @updatePageNum="updateData2">
            </Paging>
        </el-dialog>
        <el-dialog title="添加/编辑分类" :visible.sync="showAddClassify" width="400px">
            <el-form :model="addClassifyForm" status-icon :rules="rules" ref="form" label-width="100px"
                class="demo-ruleForm">
                <el-form-item label="分类名称：" prop="name">
                    <el-input v-model="addClassifyForm.name" placeholder="请输入分类名称" size="small"></el-input>
                </el-form-item>
                <el-form-item label="排序：" prop="sort">
                    <el-input v-model.number="addClassifyForm.sort" placeholder="请输入排序数值" size="small"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="showAddClassify = !1">取 消</el-button>
                <el-button size="small" type="primary" @click="comfirmClassifyForm">确 定</el-button>
            </span>
        </el-dialog>
    </el-main>
</template>

<script>
import Paging from '@/components/paging';
export default {
    components: {
        Paging,
    },
    data () {
        return {
            status: '',
            title: '',
            cate_id: '',
            page: 1,
            rows: 10,
            total: 0,
            list: [],
            showClassify: !1,
            showAddClassify: !1,
            courseClassify: [],
            courseClassifyList: [],
            classifyPage: 1,
            classifyRows: 5,
            classifyTotal: 0,
            addClassifyForm: {
                sort: '',
                name: '',
            },
            rules: {
                name: { required: true, message: '分类名称不能为空', trigger: 'blur' },
                sort: { required: true, message: '排序数值不能为空', trigger: 'blur' },
            },
        };
    },
    created () {
        this.getList();
        this.getCourseClassify();
        this.$axios
            .post(this.$api.beauty.courseCateList, {
                rows: 1000,
            })
            .then(res => {
                if (res.code == 0) {
                    this.courseClassify = res.result.list;
                }
            });
    },
    methods: {
        updateData (val, status) {
            if (status == 0) {
                this.rows = val;
            } else {
                this.page = val;
            }
            this.getList();
        },
        updateData2 (val, status) {
            if (status == 0) {
                this.classifyRows = val;
            } else {
                this.classifyPage = val;
            }
            this.getCourseClassify();
        },
        search () {
            this.page = 1;
            this.getList();
        },
        cancelSearch () {
            this.title = '';
            this.status = '';
            this.cate_id = '';
            this.page = 1;
            this.rows = 10;
            this.getList();
        },
        getList () {
            let obj = {
                page: this.page,
                rows: this.rows,
                title: this.title
            };
            if (this.cate_id) obj.cate_id = this.cate_id;
            if (typeof this.status == "number") obj.status = this.status;
            this.$axios
                .post(this.$api.beauty.courseList, obj)
                .then(res => {
                    if (res.code == 0) {
                        this.list = res.result.list;
                        this.total = res.result.total;
                    } else {
                        this.$message.error(res.msg);
                    }
                });
        },
        getCourseClassify () {
            this.$axios
                .post(this.$api.beauty.courseCateList, {
                    page: this.classifyPage,
                    rows: this.classifyRows,
                })
                .then(res => {
                    if (res.code == 0) {
                        this.courseClassifyList = res.result.list;
                        this.classifyTotal = res.result.total;
                    }
                });
        },
        updateDate (data, type) {
            let obj = {
                title: data.title,
                image: data.image,
                price: data.price,
                virtual_num: data.virtual_num,
                recommended: data.recommended,
                content: data.content,
                id: data.id,
                sort: data.sort,
            };
            if (type == 2) obj.status = data.status ? 0 : 1;
            this.$axios.post('/Beauty/Course/edit', obj).then(res => {
                if (res.code == 0) {
                    if (type == 2) this.getList();
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        addClassify () {
            this.addClassifyForm = {
                sort: '',
                name: '',
            };
            this.showAddClassify = !0;
        },
        comfirmClassifyForm () {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.$axios.post(this.addClassifyForm.id ? '/Beauty/CourseCate/edit' : '/Beauty/CourseCate/add', this.addClassifyForm).then(res => {
                        if (res.code == 0) {
                            this.getCourseClassify();
                            this.showAddClassify = !1;
                            this.$message.success(this.addClassifyForm.id ? '编辑成功' : '添加成功');
                        } else {
                            this.$message.error(res.msg);
                        }
                    });
                }
            });
        },
        del (id) {
            this.$confirm('请确认是否删除', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                this.$axios
                    .post('/Beauty/Course/del', {
                        id: id,
                    })
                    .then(res => {
                        if (res.code == 0) {
                            this.$message.success('删除成功');
                            this.getList();
                        } else {
                            this.$message.error(res.msg);
                        }
                    });
            });
        },
        delClassify (row) {
            this.$confirm('此操作将永久删除该分类, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                this.$axios
                    .post('/Beauty/CourseCate/del', {
                        id: row.id,
                    })
                    .then(res => {
                        if (res.code == 0) {
                            this.$message.success('删除成功');
                            this.getCourseClassify();
                        } else {
                            this.$message.error(res.msg);
                        }
                    });
            });
        },
        editClassify (row) {
            this.edit_id = row.id;
            this.addClassifyForm.sort = row.sort;
            this.addClassifyForm.name = row.name;
            this.addClassifyForm.id = row.id;
            this.showAddClassify = !0;
        },
    },
};
</script>

<style lang="less" scoped>
.el-main {
    background: #fff;

    .header {
        margin-bottom: 15px;
    }

    .el-table {
        border-left: 1px solid #e8eef1;
        border-right: 1px solid #e8eef1;

        /deep/ .el-input__inner {
            border-color: #fff;
        }

        /deep/ .el-input__inner:focus {
            border-color: #409eff;
        }
    }
}</style>
